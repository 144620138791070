import TextCard from '~/components/molecules/TextCard'
import Image from '~/components/atoms/Image'
import React from 'react'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function HeroAbout({key, index, ...props}) {

  const section = props.heroAbout

  return (
    <section id={section.fieldGroupName + index}  className={`mb-20 md:mb-36 py-40 pb-0 text-white`}>
      <div className="container">
        <div className="max-w-2xl mr-auto mb-20 lg:mb-44">
          <TextCard {...section.textCard}/>
        </div>
      </div>
      <FadeInWhenVisible className="container pr-0 relative">
        <Image loading={'eager'} data={section.illustration} className="w-48 lg:w-72 !hidden md:!block h-auto !absolute -top-16 lg:top-0 transform -translate-y-1/3 lg:-translate-y-2/3 right-12 z-10" />
        <div className="aspect-w-2 aspect-h-1">
          <Image loading={'eager'} data={section.image} className="!absolute w-full h-full" />
        </div>
      </FadeInWhenVisible>
    </section>
  )
}
